import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';
import Moment from 'moment';

Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$http=axios
Vue.prototype.moment = Moment;

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title,
    document.body.style.backgroundColor="#ffffff";
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import base from './base'//引用
Vue.use(base);//将全局函数当做插件来进行注册

import  VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)

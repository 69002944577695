const API_VERSION = "/v2.4";

const UrlEnum = {
    ADS:                        API_VERSION + "/admin/ads",
    AD_POSITIONS:               API_VERSION + "/admin/ad/positions",
    ADMINS:                     API_VERSION + "/admin/admins",
    ADMINS_PASSWORD:            API_VERSION + "/admin/admins/password",
    ADMIN_AUTHORIZATION:        API_VERSION + "/admin/admins/authorization",
    ADMIN_MY_PROJECT:           API_VERSION + "/admin/admins/my/project",
    ASSISTANT:                  API_VERSION + "/admin/assistants",
    BRANCHES:                   API_VERSION + "/admin/branches",
    COURSE:                     API_VERSION + "/admin/courses",
    COURSE_BRANCH_USE:          API_VERSION + "/admin/course/branch-use",
    COURSE_CATEGORY:            API_VERSION + "/admin/course/categorys",
    COURSE_QRCODE:              API_VERSION + "/admin/course/qrcode",
    COURSE_TREE_DROPDOWN:       API_VERSION + "/admin/courses/tree/dropdown",
    DISTRIBUTION_ACTIVITIES:    API_VERSION + "/admin/distribution/activities",
    DISTRIBUTION_PROMOTERS:     API_VERSION + "/admin/distribution/promoters",
    FINANCE_WITHDRAWS:          API_VERSION + "/admin/finance/withdraws",
    INTEGRAL_TASKS:             API_VERSION + "/admin/integral/tasks",
    INTEGRAL_TYPES:             API_VERSION + "/admin/integral/types",
    LESSON:                     API_VERSION + "/admin/lessons",
    LESSON_TEACHERS:            API_VERSION + "/admin/lesson/teachers",
    LESSON_LIVE_SIGN:           API_VERSION + "/admin/lessons/live/sign",
    LESSONS_PLAYBACK_TOKEN:     API_VERSION + "/admin/lessons/playback/token",
    LESSONS_VOD_TOKEN:          API_VERSION + "/admin/lessons/vod/token",
    MAJORS:                     API_VERSION + "/admin/majors",
    MODULE_TREE:                API_VERSION + "/admin/modules/tree",
    ORDER:                      API_VERSION + "/admin/orders",
    ORDER_REPORT:               API_VERSION + "/admin/order/report",
    ORDER_STATUS:               API_VERSION + "/admin/order/statuses",
    QUESTION:                   API_VERSION + "/admin/questions",
    QUESTION_TYPE:              API_VERSION + "/admin/question/types",
    RESOURCE:                   API_VERSION + "/admin/resources",
    RESOURCE_CATEGORIES:        API_VERSION + "/admin/resource/categories",
    ROLES:                      API_VERSION + "/admin/roles",
    ROLE_MODULES:               API_VERSION + "/admin/role/modules",
    SOURCES_TREE:               API_VERSION + "/admin/question/sources/tree",
    STUDENT:                    API_VERSION + "/admin/students",
    STUDENT_INTEGRAL_TASK:      API_VERSION + "/admin/student/integral-task",
    STUDENT_LESSON_RECORDS:     API_VERSION + "/admin/student/lesson-records",
    STUDENT_QUESTION_RECORDS:   API_VERSION + "/admin/student/question-records",
    SUBJECT:                    API_VERSION + "/admin/subjects",
    TB_ACTIVITIES:              API_VERSION + "/admin/tb/activities",
    TEACHER:                    API_VERSION + "/admin/teachers",
    UPLOAD_PIC:                 API_VERSION + "/admin/upload/pics",
    UPLOAD_POSTER:              API_VERSION + "/admin/upload/posters",
    YEARS:                      API_VERSION + "/admin/years",
}

export const LessonTypeEnum = {
    VOD: 0,
    LIVE: 1,
    LIVE_SUPPLY: 2
};

export const LiveStatusEnum = {
    NOT_START: 1,
    LIVING: 2,
    LIVE_END: 3,
    TRANSCODING: 4,
    TRANSCODE_FAIL: 5,
    TRANSCODE_SUCCESS: 6
};

export  const LinkTypeEnum = {
    AD_CONTENT: 0,
    COURSE_DETAIL: 1
};

export const TaskCategoryEnum = {
    RI_CHANG: 1,
    DU_XUE: 2,
    MATCH:3,
    MRYL:4
};

export const QuestionTypeEnum = {
    KE_GUAN_TI: 1,
    ZHU_GUAN_TI: 2,
    DAN_XUAN: 4,
    DUO_XUAN: 5,
    BU_DING_XIANG: 6,
    PAN_DUAN: 7,
    TIAN_KONG: 8,
    CAILIAO_CHILD: 9,
    CAILIAO: 10,
    XUAN_ZE:11,
    JIAN_DA:12,
    LUN_SHU:13,
    FA_TIAO:14,
    AN_LI:15,
    FEN_XI:16,
    FENXI_LUNSHU:17
};

/* 是否手机号码*/
export function isPhone(value) {
    const reg = /^[1][3,4,5,7,8][0-9]{9}$/;
    if (value == '' || value == undefined || value == null) {
        return false;
    } else {
        if ((!reg.test(value)) && value != '') {
            return false;
        } else {
            return true;
        }
    }
}

// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(H)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
// 例子：
// (new Date()).format("yyyy-MM-dd HH:mm:ss.S") ==> 2021-07-02 08:09:04.423
// (new Date()).format("yyyy-M-d H:m:s.S")      ==> 2021-7-2 8:9:4.18
Date.prototype.format = function(fmt){
    var o = {
        "M+" : this.getMonth()+1,                 //月份
        "d+" : this.getDate(),                    //日
        "h+" : this.getHours(),                   //小时
        "m+" : this.getMinutes(),                 //分
        "s+" : this.getSeconds(),                 //秒
        "q+" : Math.floor((this.getMonth()+3)/3), //季度
        "S"  : this.getMilliseconds()             //毫秒
    };
    if(/(y+)/.test(fmt))
        fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length));
    for(var k in o)
        if(new RegExp("("+ k +")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
    return fmt;
}

export const BranchEnum = {
    ZONG_XIAO:1,
    FEN_XIAO:0
}

export const CourseCategoryEnum = {
    XXB:1,
    ZBK:2,
    SSK:3,
    MSK:4,
    WSK:5,
    TS:6,
    FS_MFK:7,
    FS_WSK:8,
    FS_ZBK:9,
    FS_MSK:10,
    FS_TS:12,
    FK_MATCH:13,
    FS_MATCH:14
}

export const TipEnum = {
    SUBMIT_SUCCESS: "提交成功",
    DELETE_SUCCESS: "删除成功",
    DELETE_CONFIRM: "确定删除此信息？",
    NO_SELECT: "请选择要删除的数据"
};

export const ActivityTypeEnum = {
    PROMOTER:"PROMOTER",
    FISSION:"FISSION"
};

//获取地址栏参数
export function request(name) {
    var strHref = window.document.location.href;
    var intPos = strHref.indexOf("?");
    var strRight = strHref.substr(intPos + 1);

    var arrTmp = strRight.split("&");
    for (var i = 0; i < arrTmp.length; i++) {
        var arrTemp = arrTmp[i].split("=");

        if (arrTemp[0].toUpperCase() == name.toUpperCase()) return arrTemp[1];
    }
    return "";
}

export const ProjectEnum = {
    FA_KAO: 1,
    FA_SHUO: 2
};

export function getLocalProjectId() {
    const projectId = sessionStorage.getItem("projectId");
    if(projectId == null){
        loginOut();
    }
    return parseInt(projectId);
}

export function formatSeconds(value){
    let result = parseInt(value)
    let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
    let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
    let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));

    let res = '';
    res += `${h}:`;
    res += `${m}:`;
    res += `${s}`;
    return res;
}

export function getLocalBranchId() {
    const branchId = sessionStorage.getItem("branchId");
    if(branchId == null){
        loginOut();
    }
    return parseInt(branchId);
}

const ErrorEnum = {
    //用户名不能为空
    BLANK_USERNAME: "BLANK_USERNAME",
    //密码不能为空
    BLANK_PASSWORD: "BLANK_PASSWORD",
    //验证码不能为空
    BLANK_VERCODE: "BLANK_VERCODE",
    //用户名已存在
    CONFLICT_USERNAME: "CONFLICT_USERNAME",

    FORBIDDEN: "FORBIDDEN",

    //没有管理权限
    FORBIDDEN_ADMIN: "FORBIDDEN_ADMIN",
    //用户名或密码错误
    INVALID_AUTHENTICATION: "INVALID_AUTHENTICATION",
    //无效的用户名
    INVALID_USERNAME: "INVALID_USERNAME",
    //未登录
    UNAUTHORIZED: "UNAUTHORIZED",
    //服务器异常
    INTERNAL_SERVER_ERROR: "Internal Server Error"
};

export const POP_WIDTH_BIG = "65%";
export const POP_HEIGHT_BIG = "90%";
export const POP_WIDTH_MIDDLE = 690;
export const POP_HEIGHT_MIDDLE = 640;
export const POP_WIDTH_SMALL = 600;
export const POP_HEIGHT_SMALL = 450;

export function checkPassword(password) {
    var pwdRegex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,30}');
    if (!pwdRegex.test(password)) {
        return false;
    }
    return true;
}

export function setLocalProjectId(projectId) {
    sessionStorage.setItem("projectId",projectId);
}

export function setLocalBranchId(branchId){
    sessionStorage.setItem("branchId",branchId);
}

export function loginOut() {
    top.location = "/"
}

export const WithdrawStatusEnum = {
    UNAPPROVED:'UNAPPROVED',
    SUCCESS:'SUCCESS',
    REVOKE:'REVOKE'
};

export {
    UrlEnum,
    ErrorEnum,
}

export const OrderStatusEnum = {
    DZF: 1,	//待支付
    DFH: 2,	//待发货
    YFH: 3,	//已发货
    YWC: 4,	//已完成
    THSQ: 5,	//退货申请
    THZ: 6,	//退货中
    YTH: 7,	//已退货
    YGB: 8,	//已关闭
    DCT: 9,  //待成团
    BFTK: 10 //部分退款
};

export const OrderTypeEnum = {
    PU_TONG: 1,    //普通订单
    PIN_TUAN: 2,  //拼团订单
    JI_FEN: 3,      //积分订单
    UNIVERSITY: 4,  //院校调剂包
    DISTRIBUTION:5 //分销订单
};

export const TaskTypeEnum = {
    QIAN_DAO: 1, //签到
    KE_GUAN_TI: 2, //客观题
    ZHU_GUAN_TI: 3, //主观题
    TING_KE: 4, //听课
    PIN_TUAN: 5, //拼团
    GOU_KE: 6, //购课
    YAO_QING_HAO_YOU: 7, //邀请好友
    COMMENT: 8, //课程评价
    ZUO_YE_KG: 9, //作业-客观题
    ZUO_YE_ZG: 10, //作业-主观题
    PAN_DUAN:11, //判断题
    TIAN_KONG:12 //填空题
};

function checkPassword(password) {
    var pwdRegex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,30}');
    if (!pwdRegex.test(password)) {
        return false;
    }
    return true;
}

export {
    checkPassword
}
<template>
    <div id="app-home">
        <router-view></router-view>
        <el-form ref="form" :model="form" :rules="rules" label-width="0px">
            <div class="login">
                <div class="message">小嘟AI课 - 分校管理平台</div>
                <div id="darkbannerwrap"></div>
                <el-form-item label="" prop="username">
                    <el-input tabindex="1" placeholder="用户名" v-model="form.username"></el-input>
                </el-form-item>
                <el-form-item label="" prop="password">
                    <el-input  tabindex="2" placeholder="密码" v-model="form.password" type="password"  autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="" prop="verCode">
                    <el-input tabindex="3" style="width: 100px" v-model="form.verCode" type="text"  class="validate-code-input" placeholder="验证码"></el-input>
                    <img class="validate-code-img" style="display: block" :src="form.src" @click="getValidateCode()"  />
                </el-form-item>
                <el-button  tabindex="4"  :loading="loading"  @click="submit('form')">登录</el-button>
                <div style="text-align: center;padding-top: 25px; color: #DD691D" v-show="suggestShow">建议使用谷歌浏览器</div>
            </div>
        </el-form>
        <div class="copyright">Copy Right © 2020-2022 By<a href="/" target="_blank">小嘟AI课</a></div>

        <el-dialog title="请选择项目" :visible.sync="dialogVisible" width="30%" >
            <el-row>
                <el-button type="primary" style="margin-left:3%;width: 46%" plain @click="updateProjectId(1)">法考</el-button>
                <el-button type="success" style="width: 46%" plain @click="updateProjectId(2)">法硕</el-button>
            </el-row>
        </el-dialog>
    </div>

</template>

<script>
    import {checkPassword} from "../../public/admin/js/admin-vue";
    import {ErrorEnum,UrlEnum} from "../../public/js/common-vue";
    import {setLocalProjectId,setLocalBranchId} from "../../public/js/common-vue";
    import {SESSON_KEY_GLOBAL_MODULEID} from "../../public/js/sesson-key-vue";

    export default {
        data() {
            return {
                form:{
                    username: '',
                    password: '',
                    verCode:'',
                    src:''
                },
                rules:{
                    username :[{required: true, message: '请输入用户名', trigger: 'blur'}],
                    password :[{required: true, message:'请输入密码',trigger:'blur'}],
                    verCode  :[{required: true, message:'请输入验证码',trigger:'blur'}]
                },
                loading: false,
                dialogVisible: false,
                suggestShow:false
            }
        },
        mounted() {
          this.initSuggest();
          setTimeout(()=>{
              this.getValidateCode()
          },20)
        },
        created() {
            const that = this;
            document.onkeydown = function(e) {
                const key = e.keyCode;
                if (key === 13) {
                    that.submit('form');
                }
            }
        },
        methods: {
            submit(formData) {
                this.$refs[formData].validate((valid) => {
                    if (valid) {
                        if (this.form.password.length < 6 || (!checkPassword(this.form.password))) {
                            this.showMsgError("密码错误");
                            return;
                        }
                        this.loading=true;
                        setTimeout(()=>{
                            this.authorization();
                        },500)
                    }
                });
            },
            getValidateCode() {
                this.form.src = UrlEnum.ADMINS+"/code?date=" + new Date().valueOf();
            },
            updateProjectId(projectId){
                this.$http({
                    method: "put",
                    url: UrlEnum.ADMINS+'/my/project',
                    data: {"projectId":projectId}
                })
                    .then((res) => {
                        setLocalProjectId(projectId);
                        this.goMain(projectId)
                    })
                    .catch((res) => {
                        if(res.response != null){
                            const data = res.response.data;
                            this.showMsgError(data.message);
                            this.getValidateCode();
                        }
                    });
            },
            goMain(){
                window.location.href = 'main.html';
            },
            authorization(){
                this.$http({
                    method: "post",
                    url: UrlEnum.ADMINS+'/authorization',
                    data: this.form,
                })
                .then((res) => {
                    this.loading=false;
                    const data = res.data;
                    localStorage.setItem(SESSON_KEY_GLOBAL_MODULEID,data.moduleIds);
                    const projectId = data.projectId;
                    const branchId = data.branchId;
                    if(projectId == 0){
                        this.dialogVisible=true;
                    }
                    else{
                        setLocalProjectId(projectId);
                        setLocalBranchId(branchId);
                        this.goMain();
                    }
                })
                .catch((res) => {
                    this.loading = false;
                    if(res.response != null){
                        var data = res.response.data;
                        this.showMsgError(data.message);
                        if(data.error == ErrorEnum.FORBIDDEN){
                            this.getValidateCode();
                        }
                    }
                });
            },
            initSuggest(){
              const userAgent = navigator.userAgent;
              if (userAgent.indexOf("Chrome") == -1) {
                this.suggestShow=true;
              }
            }
        }
    }
</script>

<style>
    @import "../../static/css/home.css";
</style>

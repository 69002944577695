<template>
  <div id="app">
    <keep-alive include='TaskDetail,MockPaperSave,SharePaperSave,TaskSave,ExamRecord'>
      <router-view/>
    </keep-alive>


<!--    2. 将缓存 name 为 a 或者 b 的组件，结合动态组件使用-->
<!--    <keep-alive include='a,b'>-->
<!--      <router-view/>-->
<!--    </keep-alive>-->
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

exports.install = function (Vue, options) {
    Vue.prototype.request = function (name){
        var strHref = window.document.location.href;
        var intPos = strHref.indexOf("?");
        var strRight = strHref.substr(intPos + 1);

        var arrTmp = strRight.split("&");
        for (var i = 0; i < arrTmp.length; i++) {
            var arrTemp = arrTmp[i].split("=");
            if (arrTemp[0].toUpperCase() == name.toUpperCase()) return arrTemp[1];
        }
        return "";
    };

    Vue.prototype.showMsgSuccess = function (message) {
        this.$message({
            showClose: false,
            message:message,
            type: 'success'
        });
    };

    Vue.prototype.showMsgError = function (message) {
        this.$message({
            showClose: false,
            message:message,
            type: 'error'
        });
    };
};
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
//修复路由重复控制台报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
//路由配置
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title:'小嘟AI课 - 分校管理平台'
        }
    },
    {
        path: '/ad/list',
        component: ()=>import('../views/ad/List')
    },
    {
        path: '/ad/save',
        component: ()=>import('../views/ad/Save')
    },
    {
        path: '/admin/list',
        component: ()=>import('../views/admin/List')
    },
    {
        path:'/admin/reset',
        component: ()=>import('../views/admin/Reset')
    },
    {
        path: '/admin/save',
        component: ()=>import('../views/admin/Save')
    },
    {
        path:'/finance/withdraw/list',
        name:'BranchWithdrawList',
        component: ()=>import('../views/finance/withdraw/List')
    },
    {
        path:'/finance/withdraw/save',
        name:'BranchWithdrawSave',
        component: ()=>import('../views/finance/withdraw/Save')
    },
    {
        path:'/course/book/list',
        name:'BookList',
        component:()=>import('../views/course/book/List')
    },
    {
        path:'/course/book/save',
        name:'BookSave',
        component: ()=>import('../views/course/book/Save')
    },
    {
        path: '/course/list',
        name:'CourseList',
        component: ()=>import('../views/course/List')
    },
    {
        path: '/course/qrcode',
        name:'QrCode',
        component: ()=>import('../views/course/QrCode')
    },
    {
        path:'/course/save',
        name:'CourseSave',
        component: ()=>import('../views/course/Save')
    },
    {
        path: '/course/task-report',
        name:'TaskReport',
        component: ()=>import('../views/course/TaskReport')
    },
    {
        path: '/distribution/activity/list',
        component: ()=>import('../views/distribution/activity/List')
    },
    {
        path: '/distribution/activity/save',
        component: ()=>import('../views/distribution/activity/Save')
    },
    {
        path: '/distribution/promoter/list',
        name:'PromoterList',
        component: ()=>import('../views/distribution/promoter/List')
    },
    {
        path: '/distribution/promoter/report',
        component: ()=>import('../views/distribution/promoter/Report')
    },
    {
        path: '/distribution/promoter/save',
        name:'PromoterSave',
        component: ()=>import('../views/distribution/promoter/Save')
    },
    {
        path: '/integral/task/save',
        name:'TaskSave',
        component: ()=>import('../views/integral/task/Save')
    },
    {
        path: '/integral/task/detail',
        name:'TaskDetail',
        component: ()=>import('../views/integral/task/Detail'),
    },
    {
        path: '/integral/task/detail-exam',
        name:'TaskDetailExam',
        component: ()=>import('../views/integral/task/DetailExam'),
    },
    {
        path:'/integral/task/list',
        name:'TaskList',
        component: ()=>import('../views/integral/task/List'),
    },
    {
        path: '/integral/task/question-record',
        name:'QuestionRecord',
        component: ()=>import('../views/integral/task/QuestionRecord')
    },
    {
        path: '/lesson/add',
        name:'AddLesson',
        component: ()=>import('../views/lesson/Add')
    },
    {
        path: '/lesson/edit',
        name:'EditLesson',
        component: ()=>import('../views/lesson/Edit')
    },
    {
        path: '/lesson/list',
        name:'LessonList',
        component: ()=>import('../views/lesson/List')
    },
    {
        path: '/lesson/play',
        name:'PlayLesson',
        component: ()=>import('../views/lesson/Play')
    },
    {
        path: '/lesson/select',
        name:'SelectLesson',
        component: ()=>import('../views/lesson/Select')
    },
    {
        path: '/order/list',
        name:'OrderList',
        component: ()=>import('../views/order/List')
    },
    {
        path: '/order/report',
        name:'OrderReport',
        component: ()=>import('../views/order/Report')
    },
    {
        path: '/question/select',
        name:'SelectQuestion',
        component: ()=>import('../views/question/Select')
    },
    {
        path:'/resource/category/add',
        name:'AddResourceCategory',
        component:()=>import('../views/resource/category/Add')
    },
    {
        path:'/resource/category/edit',
        name:'EditResourceCategory',
        component:()=>import('../views/resource/category/Edit')
    },
    {
        path: '/resource/category/list',
        name:'ResourceCategoryList',
        component: ()=>import('../views/resource/category/List')
    },
    {
        path: '/resource/list',
        name: 'ResourceList',
        component: ()=>import('../views/resource/List')
    },
    {
        path: '/role/list',
        name:'RoleList',
        component: ()=>import('../views/role/List')
    },
    {
        path: '/role/save',
        name:'RoleSave',
        component: ()=>import('../views/role/Save')
    },
    {
        path:'/student/add',
        name:'AddStudent',
        component: ()=>import('../views/student/Add')
    },
    {
        path: '/student/lesson-record/list',
        name:'LessonRecord',
        component: ()=>import('../views/student/lesson-record/List')
    },
    {
        path: '/student/question-record/list',
        name:'QuestionRecordList',
        component: ()=>import('../views/student/question-record/List')
    },
    {
        path:'/student/list',
        name:'StudentList',
        component: ()=>import('../views/student/List')
    },
    {
        path:'/teacher/list',
        name:'TeacherList',
        component: ()=>import('../views/teacher/List')
    },
    {
        path: '/teacher/save',
        name:'TeacherSave',
        component: ()=>import('../views/teacher/Save')
    },
    {
        path: '/tb/activity/list',
        name:'ActivityList',
        component: ()=>import('../views/tb/activity/List')
    },
]

const router = new VueRouter({
    mode:'history',
    routes
})

export default router
